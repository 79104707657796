<template>
  <div class="d-flex call-to-action primary br-8">
    <div
      v-if="element.config.image_url"
      class="--img"
      :class="{ 'order-1': element.config.image_position === 'right' }"
      :style="`background-image: url(${element.config.image_url})`"
    ></div>
    <div class="--text pa-4" :class="[`text-${element.config.align}`]">
      <p v-if="element.config.title" class="font-weight-bold">
        {{ element.config.title }}
      </p>
      <p>{{ element.config.text }}</p>
      <v-btn
        class="d-inline"
        elevation="0"
        small
        rounded
        :color="element.config.button_color"
        :outlined="element.config.button_outlined"
        ><span
          class="no-text-transform font-weight-bold"
          :class="{
            'primary--text':
              element.config.button_color === 'white' &&
              !element.config.button_outlined,
          }"
          >{{ element.config.cta_text }}</span
        ></v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "CallToActionPreview",
  props: {
    element: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
.call-to-action {
  overflow: hidden;
  .--text {
    color: white;
    width: 50%;
  }
  .--img {
    background-position: center;
    background-size: cover;
    width: 50%;
  }
}
</style>
