<template>
  <div class="elements justify-left flex-column">
    <v-alert type="info" border="left" colored-border>
      <p>
        You can use + button below to create new elements for your home page.
        They will all be displayed underneath your main home page.
      </p>

      <v-btn rounded color="primary" :href="selected_brand.url" target="_blank"
        ><span class="no-text-transform">Preview Website</span></v-btn
      >
    </v-alert>
    <v-divider class="pb-4" />
    <div
      v-for="(el, idx) in selected_brand.home_page_elements"
      :key="`hp_el_${el.id}`"
      class="element mb-4 fade-in"
    >
      <template v-if="el.element_type === 'call-to-action'">
        <CallToActionPreview :element="el" />
      </template>
      <template v-else-if="el.element_type === 'latest-news'">
        <LatestNewsPreview
          :latest-news="
            selected_brand.active_blog_posts.length
              ? selected_brand.active_blog_posts[0]
              : null
          "
        />
      </template>
      <template v-else-if="el.element_type === 'social-feed'">
        <div
          class="d-flex flex-column align-center justify-center white br-8"
          style="height: 150px"
        >
          <p class="font-weight-bold text-h3">Social Media Feed</p>
          <v-icon size="25" color="primary">mdi-twitter</v-icon>
        </div>
      </template>
      <div class="--controls d-flex flex-column align-center">
        <v-btn icon @click="(element = el), (capture_dialog = true)"
          ><v-icon color="white">mdi-pencil</v-icon></v-btn
        >
        <v-btn
          icon
          v-if="idx !== 0"
          @click="move_home_page_element_in_hierarchy(el.id, 'up')"
          ><v-icon color="white" class="rotate-180"
            >mdi-chevron-down</v-icon
          ></v-btn
        >
        <v-btn
          icon
          v-if="idx !== selected_brand.home_page_elements.length - 1"
          @click="move_home_page_element_in_hierarchy(el.id, 'down')"
          ><v-icon color="white">mdi-chevron-down</v-icon></v-btn
        >
        <v-btn
          icon
          @click="
            (selected_home_page_element = el),
              (remove_home_page_element_dialog = true)
          "
          ><v-icon color="red">mdi-delete</v-icon></v-btn
        >
      </div>
    </div>
    <div
      class="
        element
        --blank
        pa-8
        mb-10
        justify-center
        flex-column
        br-8
        text-center
      "
      @click="open_add_new"
    >
      <p class="text-h3">Add home page element</p>
      <v-icon size="60">mdi-table-row-plus-after</v-icon>
    </div>

    <AdminDialog
      :show_dialog.sync="capture_dialog"
      :header="
        element.id ? 'Edit home page element' : 'Add new home page element'
      "
      :width="700"
      @confirm="upsert_home_page_element()"
      confirm_text="Save"
      :disabled="!form_valid"
    >
      <template v-slot:[`content`] v-if="capture_dialog">
        <v-form v-model="form_valid">
          <v-select
            v-model="element.element_type"
            label="Element Type"
            :items="element_types"
            class="required"
            :rules="[field_req]"
          />
          <template v-if="element.element_type === 'call-to-action'">
            <CallToActionCaptureWithImageForm
              :element="element"
              @change="(config) => $set(element, 'config', config)"
            />
          </template>
        </v-form>
      </template>
    </AdminDialog>

    <AdminDialog
      v-if="remove_home_page_element_dialog"
      :show_dialog.sync="remove_home_page_element_dialog"
      header="Are you sure?"
      @confirm="delete_home_page_element(selected_home_page_element)"
      :loading="is_deleting"
      confirm_text="Delete"
      confirm_color="red"
    >
      <template v-slot:[`content`]>
        Do you want to delete selected home page element?
      </template>
    </AdminDialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import {
  upsert_home_page_element,
  delete_home_page_element,
  move_home_page_element_in_hierarchy,
} from "@/requests";
import { field_req } from "@/utils/form_val_rules";
import AdminDialog from "@/components/base/AdminDialog";

import LatestNewsPreview from "@/views/admin/components/home-page/previews/LatestNewsPreview";
import CallToActionPreview from "@/views/admin/components/home-page/previews/CallToActionPreview";
import CallToActionCaptureWithImageForm from "@/views/admin/components/home-page/CallToActionCaptureWithImageForm";

export default {
  name: "BrandHomePageConfigurator",
  components: {
    AdminDialog,
    LatestNewsPreview,
    CallToActionPreview,
    CallToActionCaptureWithImageForm,
  },
  data() {
    return {
      field_req,
      capture_dialog: false,
      is_deleting: false,
      form_valid: false,
      remove_home_page_element_dialog: false,
      selected_home_page_element: null,
      element: {
        config: {},
      },
      empty_element: {
        brand_id: null,
        is_active: false,
        order: 1000,
        element_type: null,
        config: {},
      },
    };
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
    element_types() {
      const elements = [
        { text: "Call-to-action with Image", value: "call-to-action" },
      ];
      if (
        this.selected_brand.active_blog_posts.length &&
        !this.selected_brand.home_page_elements.find(
          (e) => e.element_type === "latest-news"
        )
      ) {
        elements.push({ text: "Latest News", value: "latest-news" });
      }
      if (
        this.selected_brand.twitter_url &&
        !this.selected_brand.home_page_elements.find(
          (e) => e.element_type === "social-feed"
        )
      ) {
        elements.push({ text: "Social Feed", value: "social-feed" });
      }
      return elements;
    },
  },
  methods: {
    ...mapActions("SuperAdminStore", ["get_brand_by_id"]),
    async upsert_home_page_element() {
      const element = { ...this.element };
      element.brand_id = this.selected_brand.id;
      await upsert_home_page_element(element);
      await this.get_brand_by_id(this.selected_brand.id);
      this.capture_dialog = false;
    },
    open_add_new() {
      this.element = JSON.parse(JSON.stringify(this.empty_element));
      this.capture_dialog = true;
    },
    async delete_home_page_element(element) {
      await delete_home_page_element(element.id);
      await this.get_brand_by_id(this.selected_brand.id);
      this.selected_home_page_element = null;
      this.remove_home_page_element_dialog = false;
    },
    async move_home_page_element_in_hierarchy(element_id, direction) {
      await move_home_page_element_in_hierarchy(element_id, direction);
      await this.get_brand_by_id(this.selected_brand.id);
    },
  },
};
</script>

<style lang="scss" scoped>
$grey: rgb(161, 161, 161);

.elements {
  max-width: 700px;
  .element {
    transition: 0.125s ease-in-out;
    width: 100%;
    cursor: pointer;
    position: relative;

    &:hover {
      .--controls {
        opacity: 1;
      }
    }
    .--controls {
      border-radius: 8px;
      transition: 0.125s ease-in-out;
      background-color: rgba(128, 128, 128, 0.3);
      z-index: 1;
      position: absolute;
      right: -35px;
      top: 0;
      // bottom: 0;
      opacity: 0;
    }

    &.--blank {
      color: $grey;
      border: 2px dashed $grey;
      .v-icon {
        color: $grey;
      }
      &:hover {
        color: black;
        border-color: #000;

        .v-icon {
          color: black;
        }
      }
    }
  }
}

.img-preview {
  margin: 0 auto;
  background-position: center;
  background-size: cover;
  height: 150px;
  width: 50%;
}
</style>
