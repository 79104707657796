<template>
  <div
    class="
      image-preview-wrapper
      noselect
      d-flex
      align-end
      fill-height
      pb-4
      pl-13
      grey-scroll-bar
    "
    :class="{ opened: show_image_preview }"
    @click.stop="selected_url = null"
  >
    <span
      class="
        selected-images-text
        primary--text
        text-h3
        font-weight-bold
        noselet
      "
      v-if="selected_images.length"
    >
      Selected Images {{ max ? `(${selected_images.length}/${max})` : "" }}
    </span>
    <div
      class="toggle-button d-flex justify-center"
      @click="toggle_image_preview"
      v-if="selected_images.length"
    >
      <span class="white--text font-weight-bold">{{
        show_image_preview ? "Hide Preview" : "Show Preview"
      }}</span>
    </div>
    <div
      v-for="(image, i) in selected_images"
      :key="`preview_${i}`"
      class="fill-height"
    >
      <v-hover v-slot="{ hover }">
        <div
          class="
            image-item
            fill-height
            d-flex
            flex-column
            align-center
            justify-end
            pr-13
          "
        >
          <v-icon
            class="close-button"
            v-if="hover"
            @click="deselect_image(image)"
            :disabled="!hover"
          >
            mdi-close
          </v-icon>
          <div>
            <img
              v-if="image.thumbnail_url"
              class="mt-4 mb-2"
              :src="image.thumbnail_url"
              height="50px"
            />
            <v-icon v-else x-large class="mb-2" color="dark-grey lighten-2">
              mdi-file-image
            </v-icon>
          </div>
          <span class="dark-grey--text text-h3 font-weight-bold noselect">{{
            image.file_name
          }}</span>
        </div>
      </v-hover>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ImagePreview",
  data() {
    return {
      selected_url: null,
    };
  },
  props: {
    max: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState("FileExplorerStore", ["show_image_preview", "selected_images"]),
  },
  methods: {
    ...mapActions("FileExplorerStore", [
      "toggle_image_preview",
      "deselect_image",
    ]),
  },
};
</script>
<style lang="scss" scoped>
.image-preview-wrapper {
  margin-top: calc(210px - 173px);
  height: 173px;
  background: white;
  transition: box-shadow 0.2s ease-in-out;
  overflow-y: hidden;
  overflow-x: scroll;
  position: relative;
  &::-webkit-scrollbar {
    height: 8px;
  }

  &.opened {
    box-shadow: 0px -1px 6px -1px var(--v-dark-grey-base);
  }
  .selected-images-text {
    position: fixed;
    display: inline-block;
    width: 250px;
    top: 22%;
    left: calc(50% - 100px);
  }
  .toggle-button {
    width: 120px;
    position: fixed;
    left: 3%;
    top: 16px;
    background: var(--v-primary-base);
    border-radius: 8px 8px 0 0;

    &:hover {
      cursor: pointer;
    }
  }
}
::v-deep .image-item {
  position: relative;
  min-width: 140px;
  .close-button {
    position: absolute;
    bottom: 50%;
    right: 30px;
  }
  span {
    height: 30px;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
