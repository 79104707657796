<template>
  <v-snackbar color="foodie" v-model="show" timeout="10000">
    <div class="d-flex align-center">
      <v-icon large class="mr-3" color="white">
        mdi-information-outline
      </v-icon>
      <span class="text-h4 white--text font-weight-bold"
        >New folders will only be saved if they contain an image</span
      >
    </div>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TemporaryDirectorySnackbar",
  computed: {
    ...mapState("FileExplorerStore", ["temporary_directory_structure"]),
    show: {
      get() {
        return Object.keys(this.temporary_directory_structure).length;
      },
      set(value) {
        this.$emit("show-snackbar", value);
      },
    },
  },
};
</script>
