<template>
  <div class="fill-height">
    <div
      class="
        image-upload-wrapper
        d-flex
        align-center
        justify-center
        noselect
        px-10
        py-2
      "
      :class="{ 'flex-column': $vuetify.breakpoint.mdAndDown }"
    >
      <div
        class="
          preview-thumbnail
          fill-height
          pt-1
          d-flex
          justify-center
          align-center
        "
      >
        <img :src="`${previewData}`" class="thumbnail ma-3" height="200" />
      </div>
      <div
        class="text-wrapper fill-height d-flex flex-column pt-16 mr-5"
        :class="{ 'align-center': $vuetify.breakpoint.mdAndDown }"
      >
        <v-form v-model="is_valid">
          <v-text-field
            class="file-name"
            color="primary"
            label="Name"
            append-icon="mdi-pencil"
            v-model="file_name"
            :rules="name_rules"
          ></v-text-field>
        </v-form>
        <v-text-field
          class="file-name mt-8"
          color="primary"
          label="Path"
          append-icon="mdi-folder"
          :value="new_image_path"
          hide-details
          disabled
        ></v-text-field>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

import { field_req, valid_filename } from "@/utils/form_val_rules";

export default {
  name: "ImageUpload",
  props: {
    previewData: {
      default: null,
    },
    upload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      is_valid: false,
      file_name: null,
      extension: null,
      name_rules: [field_req, valid_filename],
    };
  },
  computed: {
    ...mapState("FileExplorerStore", [
      "image_to_upload",
      "current_path",
      "current_temporary_path",
    ]),
    new_image_path() {
      const path = this.current_path.join("/");
      const temp_path = this.current_temporary_path.join("/");
      if (path.length && temp_path.length) {
        return [path, temp_path].join("/");
      } else {
        return path || temp_path;
      }
    },
  },
  watch: {
    image_to_upload(image) {
      if (!image) return;
      [this.file_name, this.extension] = image.name.split(".");
    },
    async upload() {
      const file = new File(
        [this.image_to_upload],
        [this.file_name, this.extension].join(".")
      );
      this.$emit("upload", { file, path: this.new_image_path });
    },
    is_valid(v) {
      this.$emit("is-valid", v);
    },
  },
};
</script>
<style lang="scss" scoped>
.image-upload-wrapper {
  height: 100%;
  .preview-thumbnail {
    width: 400px;
    img {
      border-radius: 8px;
      border: 2px solid var(--v-dark-grey-base);
    }
  }
  .text-wrapper {
    width: 40%;
    @media #{map-get($display-breakpoints, 'md-and-down')} {
      width: 100%;
    }
  }
}
::v-deep .file-name {
  max-width: 250px;
  * {
    font-weight: 700;
    font-size: 14px;
    input {
      color: var(--v-dark-grey-lighten2);
    }
  }
}
</style>
