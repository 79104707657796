var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"directory-view-wrapper grey-scroll-bar d-flex flex-column br-8",on:{"click":function($event){return _vm.highlight_directory(null)}}},[_c('div',{staticClass:"navigation-bar d-flex justify-space-between"},[_c('div',[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"back-button ml-3 pr-3 no-icon-ripple",attrs:{"color":"dark-grey"},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},on),[_vm._v("mdi-close ")])]}}])},[_c('span',[_vm._v("Close")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"back-button ml-3 pr-3 no-icon-ripple",attrs:{"color":"dark-grey","disabled":!_vm.current_path.length && !_vm.current_temporary_path.length},on:{"click":function($event){$event.stopPropagation();return _vm.on_back.apply(null, arguments)}}},on),[_vm._v("mdi-arrow-left ")])]}}])},[_c('span',[_vm._v("Back")])])],1),_c('div',{staticClass:"explorer-breadcrumbs-wrapper"},[_c('div',{staticClass:"explorer-breadcrumbs noselect d-flex align-center px-5 my-1"},[_c('span',{staticClass:"directory-crumb mr-2",on:{"click":_vm.reset_path}},[_vm._v("...")]),(_vm.current_path.length)?_c('span',{staticClass:"mr-2"},[_vm._v(">")]):_vm._e(),_vm._l((_vm.current_path.join('.>.').split('.')),function(crumb,i){return _c('span',{key:("crumb_" + i),staticClass:"mr-2",class:{ 'directory-crumb': crumb != '>' },on:{"click":function($event){$event.stopPropagation();if (crumb != '>') {
              _vm.navigate_to_breadcrumb(crumb);
            }}}},[_vm._v(" "+_vm._s(crumb)+" ")])}),(_vm.current_temporary_path.length)?_c('span',{staticClass:"mr-2"},[_vm._v(">")]):_vm._e(),_vm._l((_vm.current_temporary_path.join('.>.').split('.')),function(crumb,i){return _c('span',{key:("temp_crumb_" + i),staticClass:"mr-2",class:{ 'temp-directory-crumb': crumb != '>' },on:{"click":function($event){$event.stopPropagation();if (crumb != '>') {
              _vm.navigate_to_temp_breadcrumb(crumb);
            }}}},[_vm._v(" "+_vm._s(crumb)+" ")])})],2)]),_c('div',{staticClass:"open-button d-flex justify-center"},[_c('v-btn',{staticStyle:{"margin-top":"-2px"},attrs:{"color":"primary","text":"","rounded":"","disabled":_vm.determine_disable_open},on:{"click":function($event){$event.stopPropagation();return _vm.on_open.apply(null, arguments)}}},[_vm._v(" "+_vm._s(!_vm.highlighted_image == null || (_vm.multi_select && _vm.selected_images.length) ? "Select" : "Open")+" ")])],1)]),_c('div',{staticClass:"directory-view br-8 px-3 py-6"},[_vm._l((Object.keys(_vm.current_directory).filter(
        function (dir) { return dir != ''; }
      )),function(dir,i){return _c('div',{key:("directory_" + i),staticClass:"thumbnail-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"d-flex flex-column align-center"},[_c('v-btn',{staticClass:"delete-button",attrs:{"x-small":"","fab":""},on:{"click":function($event){_vm.to_be_deleted = dir;
            _vm.show_delete_dialog = true;}}},[_c('v-icon',{attrs:{"color":"accent","small":""}},[_vm._v("mdi-delete")])],1),_c('v-icon',{staticClass:"no-icon-ripple",attrs:{"x-large":"","color":_vm.highlighted_directory == dir ? 'primary' : 'dark-grey lighten-3'},on:{"click":function($event){$event.stopPropagation();return _vm.on_dir_click(dir)}}},[_vm._v(" mdi-folder ")]),_c('span',{staticClass:"tile-text text-center text-h5 font-weight-bold noselect",class:_vm.highlighted_directory == dir
              ? 'primary--text'
              : 'dark-grey--text text--lighten-2',on:{"click":function($event){$event.stopPropagation();return _vm.on_dir_click(dir)}}},[_vm._v(" "+_vm._s(dir)+" ")])],1)])}),(_vm.adding_new_folder)?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-icon',{attrs:{"x-large":"","color":"accent lighten-2"}},[_vm._v(" mdi-folder ")]),_c('v-text-field',{ref:"folder_input",staticClass:"new-folder-text",attrs:{"autofocus":"","hide-details":"","color":"accent lighten-2"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.adding_new_folder = false;
          _vm.new_folder_name = '';},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit_new_folder.apply(null, arguments)}],"blur":_vm.submit_new_folder},model:{value:(_vm.new_folder_name),callback:function ($$v) {_vm.new_folder_name=$$v},expression:"new_folder_name"}})],1):_vm._e(),_vm._l((Object.keys(_vm.current_temporary_directory)),function(dir,i){return _c('div',{key:("temp_directory_" + i)},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-icon',{staticClass:"no-icon-ripple",attrs:{"x-large":"","color":_vm.highlighted_temporary_directory == dir
              ? 'primary'
              : 'accent lighten-1'},on:{"click":function($event){$event.stopPropagation();return _vm.on_temp_dir_click(dir)}}},[_vm._v(" mdi-folder ")]),_c('span',{staticClass:"tile-text text-h5 font-weight-bold noselect",class:_vm.highlighted_temporary_directory == dir
              ? 'primary--text'
              : 'dark-grey--text text--lighten-2',on:{"click":function($event){$event.stopPropagation();return _vm.on_temp_dir_click(dir)}}},[_vm._v(" "+_vm._s(dir)+" ")])],1)])}),_vm._l((_vm.current_directory_images),function(image,i){return _c('div',{key:("image_" + i),staticClass:"thumbnail-wrapper"},[_c('v-tooltip',{attrs:{"right":"","disabled":_vm.multi_select || _vm.$vuetify.breakpoint.xsOnly,"content-class":"preview-tooltip","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex flex-column align-center justify-end noselect"},on),[_c('v-btn',{staticClass:"delete-button",attrs:{"x-small":"","fab":""},on:{"click":function($event){_vm.to_be_deleted = image;
                _vm.show_delete_dialog = !!image;}}},[_c('v-icon',{attrs:{"color":"accent","small":""}},[_vm._v("mdi-delete")])],1),(!image.thumbnail_url)?_c('v-icon',{staticClass:"no-icon-ripple",attrs:{"x-large":"","color":_vm.highlighted_image && _vm.highlighted_image.id == image.id
                  ? 'primary'
                  : 'dark-grey lighten-3'},on:{"click":function($event){$event.stopPropagation();return _vm.on_image_click(image)}}},[_vm._v(" mdi-file-image ")]):_c('img',{staticClass:"mb-2",class:{
                'highlighted-image':
                  _vm.highlighted_image && _vm.highlighted_image.id == image.id,
              },attrs:{"src":image.thumbnail_url,"height":"35px"},on:{"click":function($event){$event.stopPropagation();return _vm.on_image_click(image)}}}),_c('div',{staticClass:"tile-text-wrapper d-flex justify-center align-center"},[(_vm.multi_select)?_c('v-checkbox',{staticClass:"image-checkbox mr-1",attrs:{"input-value":_vm.selected_image_ids.includes(image.id),"hide-details":"","readonly":""}}):_vm._e(),_c('span',{staticClass:"tile-text text-h5 font-weight-bold noselect",class:_vm.highlighted_image && _vm.highlighted_image.id == image.id
                    ? 'primary--text'
                    : 'dark-grey--text text--lighten-2',on:{"click":function($event){$event.stopPropagation();return _vm.on_image_click(image)}}},[_vm._v(" "+_vm._s(image.file_name)+" ")])],1)],1)]}}],null,true)},[(image.thumbnail_url)?_c('img',{attrs:{"src":image.thumbnail_url,"height":"150px"}}):_vm._e()])],1)})],2),_c('v-speed-dial',{staticClass:"add-new-button",attrs:{"transition":"slide-y-reverse-transition","absolute":"","direction":"top","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"dark":"","fab":"","color":"primary"},model:{value:(_vm.speed_dial),callback:function ($$v) {_vm.speed_dial=$$v},expression:"speed_dial"}},[_c('v-icon',{attrs:{"x-large":!_vm.speed_dial}},[_vm._v(" mdi-plus")])],1)]},proxy:true}]),model:{value:(_vm.speed_dial),callback:function ($$v) {_vm.speed_dial=$$v},expression:"speed_dial"}},[_c('v-btn',{attrs:{"small":"","dark":"","fab":"","color":"foodie"},on:{"click":function($event){$event.stopPropagation();return _vm.toggle_show_image_uploader.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-file-image-plus-outline")])],1),_c('v-btn',{attrs:{"small":"","dark":"","fab":"","color":"accent"},on:{"click":function($event){$event.stopPropagation();_vm.adding_new_folder = true}}},[_c('v-icon',[_vm._v("mdi-folder-multiple-plus")])],1)],1),_vm._t("default"),_c('AdminDialog',{attrs:{"show_dialog":_vm.show_delete_dialog,"header":_vm.confirmation_dialog_header,"disabled":_vm.is_sending_images,"confirm_text":'Delete',"confirm_color":'accent'},on:{"update:show_dialog":function($event){_vm.show_delete_dialog=$event},"confirm":_vm.delete_item},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.confirmation_dialog_text)+" ")]},proxy:true}],null,true)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }