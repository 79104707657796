<template>
  <div class="fill-height px-8 py-10">
    <v-alert
      v-if="!is_selected_image_big_enough"
      :key="`$${imageHeight}x${imageWidth}`"
      type="info"
      color="red"
      colored-border
      border="left"
      dismissible
    >
      Your selected image has a small resolution of
      <b>{{ imageWidth }}x{{ imageHeight }}px</b> which will cause pixelation
      and blurriness. We recommend using images over 1000x800. Please go back
      and re-upload another image with higher resolution.
    </v-alert>
    <p class="text-h5 mb-1">
      Resize your image by using your scroll button to zoom in/out, once happy,
      drag the stencil to your desired position on your image and press crop.
    </p>
    <div class="crop-wrapper">
      <cropper
        class="cropper"
        ref="cropper"
        :src="image"
        :stencil-props="{
          aspectRatio: active_ratio,
        }"
        image-restriction="stencil"
        foreground-class="white"
        background-class="grey"
        :minHeight="480"
      />
      <div class="d-flex flex-column justify-center --controls">
        <v-btn icon @click="flip(true, false)" class="mb-2"
          ><v-icon size="28" color="white">mdi-flip-horizontal</v-icon></v-btn
        >
        <v-btn icon @click="rotate(90)" class="mb-2"
          ><v-icon size="28" color="white">mdi-reload</v-icon></v-btn
        >
        <v-btn icon @click="rotate(-90)"
          ><v-icon size="28" color="white">mdi-restore</v-icon></v-btn
        >
      </div>
      <v-speed-dial
        v-if="aspect_ratios.length > 1"
        v-model="speed_dial"
        class="aspect-ratio-fab"
        transition="slide-y-transition"
        absolute
        direction="bottom"
        open-on-hover
        ><template v-slot:activator>
          <v-btn v-model="speed_dial" color="primary" dark fab>
            <v-icon :large="!speed_dial"> mdi-aspect-ratio </v-icon>
          </v-btn>
        </template>
        <v-btn
          v-for="aspect_ratio in aspect_ratios"
          :key="`ratio_${aspect_ratio.text}`"
          dark
          rounded
          class="aspect-btn wide"
          color="mb-2 primary darken-3"
          @click="active_ratio = aspect_ratio.ratio"
          >{{ aspect_ratio.text }}
        </v-btn>
      </v-speed-dial>
    </div>
  </div>
</template>
<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "ImageCrop",
  components: {
    Cropper,
  },
  props: {
    value: { default: null },
    imageWidth: { type: Number, required: true },
    imageHeight: { type: Number, required: true },
    crop: { type: Boolean, default: false },
    toMimeType: { type: String, default: "image/png" },
    wideRectangle: { type: Boolean, default: true },
    rectangle: { type: Boolean, default: true },
    square: { type: Boolean, deafult: true },
  },
  data() {
    return {
      speed_dial: false,
      active_ratio: 16 / 9,
    };
  },
  computed: {
    image: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    aspect_ratios() {
      const ratios = [];
      if (this.wideRectangle) ratios.push({ text: "16:9", ratio: 16 / 9 });
      if (this.rectangle) ratios.push({ text: "4:3", ratio: 4 / 3 });
      if (this.square) ratios.push({ text: "1:1", ratio: 1 });
      return ratios;
    },
    is_selected_image_big_enough() {
      if (this.imageWidth < 1000) return false;
      if (this.imageHeight < 800) return false;
      return true;
    },
  },
  mounted() {
    if (this.square) this.active_ratio = 1;
    if (this.rectangle) this.active_ratio = 4 / 3;
    if (this.wideRectangle) this.active_ratio = 16 / 9;
  },
  watch: {
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      this.$emit("crop", { canvas, type: this.toMimeType });
    },
  },
  methods: {
    rotate(angle) {
      this.$refs.cropper.rotate(angle);
    },
    flip(x, y) {
      this.$refs.cropper.flip(x, y);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .crop-wrapper {
  position: relative;
  height: 90%;
  border: 2px dashed var(--v-primary-base);
  border-radius: 8px;
  .aspect-ratio-fab {
    right: 65px;
    top: 30px;
    .aspect-btn {
      span {
        font-weight: 600;
        font-size: 13px;
      }
      &.wide {
        width: 75px;
      }
      &.medium {
        width: 60px;
      }
    }
  }
  .--controls {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.205);
    padding-left: 1rem;
    padding-right: 1rem;
    left: 0;
    top: 0;
    bottom: 0;
  }
}
.cropper {
  height: 100%;
}
</style>
