<template>
  <div class="d-flex justify-center flex-column">
    <div
      class="d-flex justify-center align-center"
      @click="$refs.uploader.$refs.input.click()"
    >
      <div
        class="drag-wrapper d-flex flex-column justify-center align-center"
        @mouseover="dragover = true"
        @mouseleave="dragover = false"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
        @drop.prevent="on_drop"
      >
        <v-icon class="cloud-icon" :class="{ active: dragover }" size="60">
          mdi-cloud-upload
        </v-icon>
        <p
          class="
            mb-0
            font-weight-bold
            text-h4
            dark-grey--text
            text--lighten-2
            noselect
          "
        >
          {{
            $vuetify.breakpoint.mdAndDown
              ? "Click to select file"
              : "Drop your file here, or click to select it"
          }}
        </p>
      </div>
    </div>

    <v-file-input
      v-show="false"
      v-model="file"
      ref="uploader"
      :accept="'.' + ALLOWED_EXTENSIONS.join(', .')"
    />
  </div>
</template>
<script>
const ALLOWED_EXTENSIONS = ["jpg", "jpeg", "webp", "png"];

export default {
  name: "ImageSelect",
  data() {
    return {
      dragover: false,
      ALLOWED_EXTENSIONS,
    };
  },
  props: {
    value: {
      type: File,
      default: null,
    },
  },
  computed: {
    file: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    on_drop(event) {
      if (event.dataTransfer?.files.length) {
        const file = Array.from(event.dataTransfer.files)[0];
        if (ALLOWED_EXTENSIONS.includes(file.name.split(".").pop())) {
          this.file = file;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.drag-wrapper {
  width: 80%;
  height: 300%;
  position: relative;
  border: 2px solid var(--v-dark-grey-lighten3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.125s ease-in-out;
  &:hover {
    border: 2px solid var(--v-primary-base);
  }
  .cloud-icon {
    width: 60px;
    color: var(--v-dark-grey-lighten2);
    transition: transform 0.125 ease-in-out;
    &.active {
      transform: translateY(-20%);
      color: var(--v-primary-base);
    }
  }
  .drop-text {
    width: 100%;
  }
}
</style>
