<template>
  <div>
    <p class="text-h4 font-weight-bold">
      Image<span class="red--text"> * </span>
    </p>
    <v-radio-group
      v-model="config.image_position"
      required
      row
      :rules="[field_req]"
    >
      <template v-slot:label>
        <div>Image position <span class="red--text">*</span></div>
      </template>
      <v-radio label="Left" value="left"></v-radio>
      <v-radio label="Right" value="right"></v-radio>
    </v-radio-group>
    <FileExplorer
      :brand_id="selected_brand.id"
      @select-image="(image) => $set(config, 'image_url', image.small_url)"
      :label="config.image_url ? 'Change Image' : 'Select Image'"
      :square="false"
      :rectangle="true"
      :wide-rectangle="false"
    />
    <div
      v-if="config.image_url"
      class="img-preview br-16 mb-4"
      :style="`background-image: url(${config.image_url});`"
    />
    <v-divider class="py-4" />
    <p class="text-h4 font-weight-bold">
      Text<span class="red--text"> * </span>
    </p>
    <v-radio-group v-model="config.align" required row :rules="[field_req]">
      <template v-slot:label>
        <div>Text align <span class="red--text">*</span></div>
      </template>
      <v-radio label="Left" value="left"></v-radio>
      <v-radio label="Center" value="center"></v-radio>
      <v-radio label="Right" value="right"></v-radio>
    </v-radio-group>
    <v-text-field label="Header" v-model="config.title" />
    <v-textarea
      label="Body Text"
      v-model="config.text"
      class="required"
      :rules="[
        field_req,
        (v) => v.length <= 160 || 'You reached maximum of 160 characters.',
      ]"
      counter="160"
    />
    <v-divider class="mt-4 py-4" />
    <p class="text-h4 font-weight-bold">
      Call To Action<span class="red--text"> * </span>
    </p>
    <!-- <v-radio-group
      v-model="config.button_color"
      required
      hide-details=""
      row
      :rules="[field_req]"
    >
      <template v-slot:label>
        <div>Button color <span class="red--text">*</span></div>
      </template>
      <v-radio label="Brand Theme" value="primary"></v-radio>
      <v-radio label="White" value="white"></v-radio>
    </v-radio-group>
    <v-checkbox v-model="config.button_outlined" label="Button Outlined">
    </v-checkbox> -->
    <v-text-field
      label="Call-to-action text"
      v-model="config.cta_text"
      class="required"
      :rules="[field_req]"
    />
    <v-text-field
      label="Call-to-action link"
      v-model="config.cta_url"
      class="required"
      :rules="[field_req, must_start_with_https]"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import FileExplorer from "@/components/file_manager/FileExplorer";

import { field_req, must_start_with_https } from "@/utils/form_val_rules";

export default {
  name: "CallToActionCaptureWithImageForm",
  components: {
    FileExplorer,
  },
  props: {
    element: { type: Object, default: null },
  },
  data() {
    return {
      field_req,
      must_start_with_https,
      config: {},
      empty_config: {
        button_outlined: false,
        button_color: "white",
        image_url: null,
        text: null,
        cta_text: null,
        cta_url: null,
      },
    };
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
  },
  mounted() {
    if (this.element) this.config = this.element.config;
    else this.config = { ...this.empty_config };
  },
  watch: {
    config: {
      handler: function (v) {
        this.$emit("change", v);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.img-preview {
  margin: 0 auto;
  background-position: center;
  background-size: cover;
  height: 150px;
  width: 70%;
}
</style>
