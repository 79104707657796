<template>
  <div
    class="directory-view-wrapper grey-scroll-bar d-flex flex-column br-8"
    @click="highlight_directory(null)"
  >
    <div class="navigation-bar d-flex justify-space-between">
      <div>
        <v-tooltip top content-class="custom-tooltip">
          <template v-slot:activator="{ on }">
            <v-icon
              class="back-button ml-3 pr-3 no-icon-ripple"
              color="dark-grey"
              v-on="on"
              @click.stop="close"
              >mdi-close
            </v-icon>
          </template>
          <span>Close</span>
        </v-tooltip>
        <v-tooltip top content-class="custom-tooltip">
          <template v-slot:activator="{ on }">
            <v-icon
              class="back-button ml-3 pr-3 no-icon-ripple"
              color="dark-grey"
              v-on="on"
              @click.stop="on_back"
              :disabled="!current_path.length && !current_temporary_path.length"
              >mdi-arrow-left
            </v-icon>
          </template>
          <span>Back</span>
        </v-tooltip>
      </div>
      <div class="explorer-breadcrumbs-wrapper">
        <div
          class="explorer-breadcrumbs noselect d-flex align-center px-5 my-1"
        >
          <span class="directory-crumb mr-2" @click="reset_path">...</span>
          <span class="mr-2" v-if="current_path.length">></span>
          <span
            v-for="(crumb, i) in current_path.join('.>.').split('.')"
            :key="`crumb_${i}`"
            class="mr-2"
            :class="{ 'directory-crumb': crumb != '>' }"
            @click.stop="
              if (crumb != '>') {
                navigate_to_breadcrumb(crumb);
              }
            "
          >
            {{ crumb }}
          </span>
          <span class="mr-2" v-if="current_temporary_path.length">></span>
          <span
            v-for="(crumb, i) in current_temporary_path.join('.>.').split('.')"
            :key="`temp_crumb_${i}`"
            class="mr-2"
            :class="{ 'temp-directory-crumb': crumb != '>' }"
            @click.stop="
              if (crumb != '>') {
                navigate_to_temp_breadcrumb(crumb);
              }
            "
          >
            {{ crumb }}
          </span>
        </div>
      </div>
      <div class="open-button d-flex justify-center">
        <v-btn
          color="primary"
          style="margin-top: -2px"
          text
          rounded
          :disabled="determine_disable_open"
          @click.stop="on_open"
        >
          {{
            !highlighted_image == null ||
            (multi_select && selected_images.length)
              ? "Select"
              : "Open"
          }}
        </v-btn>
      </div>
    </div>
    <div class="directory-view br-8 px-3 py-6">
      <div
        v-for="(dir, i) in Object.keys(current_directory).filter(
          (dir) => dir != ''
        )"
        :key="`directory_${i}`"
        class="thumbnail-wrapper"
      >
        <div class="d-flex flex-column align-center" v-show="false">
          <v-btn
            class="delete-button"
            x-small
            fab
            @click="
              to_be_deleted = dir;
              show_delete_dialog = true;
            "
          >
            <v-icon color="accent" small>mdi-delete</v-icon>
          </v-btn>
          <v-icon
            x-large
            class="no-icon-ripple"
            :color="
              highlighted_directory == dir ? 'primary' : 'dark-grey lighten-3'
            "
            @click.stop="on_dir_click(dir)"
          >
            mdi-folder
          </v-icon>
          <span
            class="tile-text text-center text-h5 font-weight-bold noselect"
            :class="
              highlighted_directory == dir
                ? 'primary--text'
                : 'dark-grey--text text--lighten-2'
            "
            @click.stop="on_dir_click(dir)"
          >
            {{ dir }}
          </span>
        </div>
      </div>
      <div class="d-flex flex-column align-center" v-if="adding_new_folder">
        <v-icon x-large color="accent lighten-2"> mdi-folder </v-icon>
        <v-text-field
          autofocus
          v-model="new_folder_name"
          class="new-folder-text"
          ref="folder_input"
          hide-details
          color="accent lighten-2"
          @keydown.esc="
            adding_new_folder = false;
            new_folder_name = '';
          "
          @keydown.enter="submit_new_folder"
          @blur="submit_new_folder"
        >
        </v-text-field>
      </div>
      <div
        v-for="(dir, i) in Object.keys(current_temporary_directory)"
        :key="`temp_directory_${i}`"
      >
        <div class="d-flex flex-column align-center">
          <v-icon
            x-large
            class="no-icon-ripple"
            :color="
              highlighted_temporary_directory == dir
                ? 'primary'
                : 'accent lighten-1'
            "
            @click.stop="on_temp_dir_click(dir)"
          >
            mdi-folder
          </v-icon>
          <span
            class="tile-text text-h5 font-weight-bold noselect"
            :class="
              highlighted_temporary_directory == dir
                ? 'primary--text'
                : 'dark-grey--text text--lighten-2'
            "
            @click.stop="on_temp_dir_click(dir)"
          >
            {{ dir }}
          </span>
        </div>
      </div>
      <div
        v-for="(image, i) in current_directory_images"
        :key="`image_${i}`"
        class="thumbnail-wrapper"
      >
        <v-tooltip
          right
          :disabled="multi_select || $vuetify.breakpoint.xsOnly"
          content-class="preview-tooltip"
          open-delay="1000"
        >
          <template v-slot:activator="{ on }">
            <div
              class="d-flex flex-column align-center justify-end noselect"
              v-on="on"
            >
              <v-btn
                class="delete-button"
                x-small
                fab
                @click="
                  to_be_deleted = image;
                  show_delete_dialog = !!image;
                "
              >
                <v-icon color="accent" small>mdi-delete</v-icon>
              </v-btn>
              <v-icon
                v-if="!image.thumbnail_url"
                x-large
                class="no-icon-ripple"
                :color="
                  highlighted_image && highlighted_image.id == image.id
                    ? 'primary'
                    : 'dark-grey lighten-3'
                "
                @click.stop="on_image_click(image)"
              >
                mdi-file-image
              </v-icon>
              <img
                v-else
                :src="image.thumbnail_url"
                class="mb-2"
                height="35px"
                @click.stop="on_image_click(image)"
                :class="{
                  'highlighted-image':
                    highlighted_image && highlighted_image.id == image.id,
                }"
              />
              <div class="tile-text-wrapper d-flex justify-center align-center">
                <v-checkbox
                  v-if="multi_select"
                  :input-value="selected_image_ids.includes(image.id)"
                  hide-details
                  readonly
                  class="image-checkbox mr-1"
                />
                <span
                  class="tile-text text-h5 font-weight-bold noselect"
                  :class="
                    highlighted_image && highlighted_image.id == image.id
                      ? 'primary--text'
                      : 'dark-grey--text text--lighten-2'
                  "
                  @click.stop="on_image_click(image)"
                >
                  {{ image.file_name }}
                </span>
              </div>
            </div>
          </template>
          <img
            :src="image.thumbnail_url"
            height="150px"
            v-if="image.thumbnail_url"
          />
        </v-tooltip>
      </div>
    </div>
    <v-speed-dial
      v-model="speed_dial"
      class="add-new-button"
      transition="slide-y-reverse-transition"
      absolute
      direction="top"
      open-on-hover
    >
      <template v-slot:activator>
        <v-btn v-model="speed_dial" dark fab color="primary">
          <v-icon :x-large="!speed_dial"> mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-btn
        small
        dark
        fab
        @click.stop="toggle_show_image_uploader"
        color="foodie"
      >
        <v-icon>mdi-file-image-plus-outline</v-icon>
      </v-btn>
      <v-btn
        small
        dark
        fab
        @click.stop="adding_new_folder = true"
        color="accent"
      >
        <v-icon>mdi-folder-multiple-plus</v-icon>
      </v-btn>
    </v-speed-dial>
    <slot />
    <AdminDialog
      :show_dialog.sync="show_delete_dialog"
      :header="confirmation_dialog_header"
      :disabled="is_sending_images"
      :confirm_text="'Delete'"
      :confirm_color="'accent'"
      @confirm="delete_item"
    >
      <template v-slot:[`content`]> {{ confirmation_dialog_text }} </template>
    </AdminDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import AdminDialog from "@/components/base/AdminDialog";

export default {
  name: "DirectoryView",
  components: {
    AdminDialog,
  },
  data() {
    return {
      speed_dial: false,
      adding_new_folder: false,
      new_folder_name: "",
      recently_clicked_dir: null,
      recently_clicked_temp_dir: null,
      recently_clicked_image: null,
      show_delete_dialog: false,
      to_be_deleted: null,
    };
  },
  props: {
    multi_select: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState("FileExplorerStore", [
      "images",
      "is_sending_images",
      "selected_images",
      "current_path",
      "current_temporary_path",
      "highlighted_directory",
      "highlighted_image",
      "highlighted_temporary_directory",
    ]),
    ...mapGetters("FileExplorerStore", [
      "current_directory",
      "current_directory_images",
      "current_temporary_directory",
      "selected_image_ids",
    ]),
    determine_disable_open() {
      if (this.multi_select && this.selected_images.length) return false;
      return (
        !this.highlighted_directory &&
        !this.highlighted_temporary_directory &&
        !this.highlighted_image?.id
      );
    },
    confirmation_dialog_header() {
      if (!this.to_be_deleted) return "";
      if (typeof this.to_be_deleted == "string") {
        return `Delete the '${this.to_be_deleted}' folder?`;
      } else {
        return `Delete '${this.to_be_deleted.file_name}'?`;
      }
    },
    confirmation_dialog_text() {
      if (!this.to_be_deleted) return "";
      if (typeof this.to_be_deleted == "string") {
        return `This will also remove all images and directories within the folder`;
      } else {
        return `This will not affect any existing news posts or home page elements`;
      }
    },
  },
  methods: {
    ...mapActions("FileExplorerStore", [
      "open_directory",
      "open_temporary_directory",
      "return_to_previous_directory",
      "return_to_previous_temp_directory",
      "highlight_directory",
      "highlight_image",
      "highlight_temporary_directory",
      "navigate_to_breadcrumb",
      "navigate_to_temp_breadcrumb",
      "reset_path",
      "create_temporary_directory",
      "toggle_show_image_uploader",
      "select_image",
      "deselect_image",
      "clear_selected_images",
      "delete_images",
    ]),
    on_open() {
      if (this.highlighted_directory) {
        this.open_directory(this.highlighted_directory);
        return;
      } else if (this.highlighted_temporary_directory) {
        this.open_temporary_directory(this.highlighted_temporary_directory);
        return;
      }

      if (this.multi_select && this.selected_images.length) {
        this.$emit("select-images", this.selected_images);
      } else if (this.highlighted_image) {
        this.$emit("select-image", this.highlighted_image);
      }
    },
    on_back() {
      if (this.current_temporary_path.length) {
        this.return_to_previous_temp_directory();
      } else {
        this.return_to_previous_directory();
      }
    },
    on_dir_click(dir) {
      this.highlight_directory(dir);
      if (this.recently_clicked_dir == dir) {
        this.open_directory(dir);
        this.recently_clicked_dir = null;
      } else {
        this.recently_clicked_dir = dir;
        setTimeout(() => {
          this.recently_clicked_dir = null;
        }, 500);
      }
    },
    on_temp_dir_click(dir) {
      this.highlight_temporary_directory(dir);
      if (this.recently_clicked_temp_dir == dir) {
        this.open_temporary_directory(dir);
        this.recently_clicked_temp_dir = null;
      } else {
        this.recently_clicked_temp_dir = dir;
        setTimeout(() => {
          this.recently_clicked_temp_dir = null;
        }, 500);
      }
    },
    on_image_click(image) {
      this.highlight_image(image);
      if (this.multi_select) {
        if (this.selected_image_ids.includes(image.id)) {
          this.deselect_image(image);
        } else if (!this.max || this.selected_images.length < this.max) {
          this.select_image(image);
        }
      } else {
        if (this.recently_clicked_image?.id == image.id) {
          this.$emit("select-image", image);
          this.recently_clicked_image = null;
        } else {
          this.recently_clicked_image = image;
          setTimeout(() => {
            this.recently_clicked_image = null;
          }, 500);
        }
      }
    },
    submit_new_folder() {
      this.adding_new_folder = false;
      if (
        !this.new_folder_name ||
        !this.is_valid_folder(this.new_folder_name)
      ) {
        this.new_folder_name = "";
        return;
      }
      this.create_temporary_directory(this.new_folder_name);
      this.new_folder_name = "";
    },
    close() {
      this.$emit("close");
    },
    async delete_item() {
      if (!this.to_be_deleted) return;
      if (typeof this.to_be_deleted == "string") {
        await this.delete_directory(this.to_be_deleted);
      } else {
        await this.delete_images({ image_ids: [this.to_be_deleted.id] });
      }
      this.show_delete_dialog = false;
      this.to_be_deleted = null;
    },
    async delete_directory(dir_name) {
      let route_to_delete = "";
      if (this.current_path.length) {
        route_to_delete = [this.current_path.join("/"), dir_name].join("/");
      } else {
        route_to_delete = dir_name;
      }
      const images_to_delete = this.images.filter((image) =>
        image.file_path.startsWith(route_to_delete)
      );
      await this.delete_images({
        image_ids: images_to_delete.map((i) => i.id),
      });
    },
    is_valid_folder(name) {
      return (
        !Object.keys(this.current_temporary_directory).includes(name) &&
        !Object.keys(this.current_directory).includes(name)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.directory-view-wrapper {
  position: relative;
  height: 100%;
  background: white;

  .navigation-bar {
    height: 30px;
    width: 100%;
    background: var(--v-light-grey-base);
    border-bottom: 3px solid var(--v-light-grey-darken1);
    z-index: 2;
    .back-button {
      border-right: 3px solid var(--v-light-grey-darken1);
      padding-top: 5px;
      padding-bottom: 3px;
    }
    .open-button {
      width: 110px;
      border-left: 3px solid var(--v-light-grey-darken1);
    }
  }
  .explorer-breadcrumbs-wrapper {
    width: calc(100% - 204px);
    overflow: hidden;
    .explorer-breadcrumbs {
      padding-top: 2px;
      span {
        font-size: 12px;
        font-weight: 400;
        color: var(--v-dark-grey-lighten2);
        &.directory-crumb {
          font-weight: 600;
          &:hover {
            cursor: pointer;
            color: var(--v-primary-base);
          }
        }
        &.temp-directory-crumb {
          font-weight: 600;
          &:hover {
            cursor: pointer;
            color: var(--v-accent-lighten1);
          }
        }
      }
    }
  }
  .directory-view {
    background: white;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-padding: 36px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      grid-template-columns: repeat(4, 1fr);
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      grid-template-columns: repeat(3, 1fr);
    }

    &::-webkit-scrollbar {
      margin-right: 5px;
      width: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: var(--v-light-grey-darken1);
    }
    & > div {
      height: 75px;
      min-width: 75px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    .thumbnail-wrapper {
      position: relative;
      &:hover {
        .delete-button {
          opacity: 100%;
        }
      }
    }
  }
  .add-new-button {
    position: absolute;
    bottom: 40px;
    right: 40px;
  }
}
::v-deep .new-folder-text {
  margin: 3px 0 0 0;
  padding: 0;
  max-width: 100px;
  * {
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: 700;
    font-size: 1.0625rem;
    color: var(--v-dark-grey-lighten2) !important;
  }
}
::v-deep .image-checkbox {
  position: absolute;
  top: -49px;
  left: 15px;
  margin: 0;
  padding: 0;
  * {
    margin: 0;
    padding: 0;
  }
  .v-input--selection-controls__ripple {
    display: none;
  }
}
.highlighted-image {
  outline: 2px solid var(--v-primary-base);
}
.tile-text-wrapper {
  height: 27px;
  width: 140px;
  position: relative;
}
.tile-text {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 140px;
  max-height: 27px;
}
.preview-tooltip {
  background-color: transparent;
  img {
    border-radius: 8px;
    border: 2px solid var(--v-light-grey-darken1);
  }
}
.delete-button {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  position: absolute;
  top: -15px;
  right: 10px;
  width: 25px;
  height: 25px;
}
</style>
