<template>
  <div>
    <v-dialog
      v-model="show"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      transition="dialog-bottom-transition"
      :max-width="$vuetify.breakpoint.mdAndDown ? '' : '800px'"
      persistent
      content-class="position-relative"
    >
      <template v-slot:activator="{ on }">
        <div class="text-center">
          <v-btn v-on="on" color="primary" class="mb-4" rounded outlined>{{
            label
          }}</v-btn>
        </div>
      </template>
      <div
        class="image-uploader-dialog"
        :class="{ active: show_image_uploader }"
      >
        <ImageUploader
          :shop_id="shop_id"
          :brand_id="brand_id"
          :square="square"
          :rectangle="rectangle"
          :wide-rectangle="wideRectangle"
        />
      </div>
      <div
        class="directory-view-dialog"
        :class="{ obscured: show_image_uploader }"
      >
        <DirectoryView
          :multi_select="multi_select"
          :max="max"
          @close="show = false"
          @select-image="on_image_select"
          @select-images="on_images_select"
        >
        </DirectoryView>
      </div>
      <div
        v-if="multi_select && !show_image_uploader"
        class="image-preview-drawer"
        :class="{ opened: show_image_preview }"
      >
        <ImagePreview :max="multi_select ? max : 0" />
      </div>
    </v-dialog>
    <TemporaryDirectorySnackbar />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import DirectoryView from "./components/DirectoryView.vue";
import TemporaryDirectorySnackbar from "./components/TemporaryDirectorySnackbar.vue";
import ImageUploader from "./components/ImageUploader.vue";
import ImagePreview from "./components/ImagePreview.vue";

export default {
  name: "FileExplorer",
  components: {
    ImageUploader,
    DirectoryView,
    TemporaryDirectorySnackbar,
    ImagePreview,
  },
  props: {
    shop_id: {
      type: String,
    },
    brand_id: {
      type: String,
    },
    label: {
      type: String,
    },
    multi_select: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 0,
    },
    wideRectangle: { type: Boolean, default: true },
    rectangle: { type: Boolean, default: true },
    square: { type: Boolean, deafult: true },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState("FileExplorerStore", [
      "show_image_uploader",
      "show_image_preview",
      "previewed_image",
    ]),
  },
  methods: {
    ...mapActions("FileExplorerStore", ["get_images"]),
    on_image_select(image) {
      this.$emit("select-image", image);
      this.show = false;
    },
    on_images_select(image) {
      this.$emit("select-images", image);
      this.show = false;
    },
  },
  mounted() {
    if (this.shop_id) {
      this.get_images({ shop_id: this.shop_id });
    } else if (this.brand_id) {
      this.get_images({ brand_id: this.brand_id });
    }
  },
};
</script>
<style lang="scss" scoped>
$duration: 0.2s;

::v-deep .v-dialog {
  border-radius: 10px !important;
  border: 4px solid var(--v-primary-base);
  min-height: 650px;
  overflow: hidden;
}
.image-uploader-dialog,
.directory-view-dialog,
.image-preview-drawer {
  height: 100%;
  transition: transform $duration ease-in-out;
  position: absolute;
  inset: 0;
}
.image-uploader-dialog {
  z-index: 0;
  transform: translateX(100%);
  &.active {
    transform: translateX(0%);
  }
}
.directory-view-dialog {
  z-index: 1;
  transform: translateX(0%);
  &.obscured {
    transform: translateX(-100%);
  }
}
.image-preview-drawer {
  top: calc(100% - 210px);
  z-index: 2;
  height: 210px;
  transition: all $duration linear;
  transform: translateY(85%);
  overflow: hidden;
  &.opened {
    transform: translateY(0%);
  }
}
</style>
