<template>
  <div class="progress-wrapper d-flex align-center px-16 pt-3">
    <v-progress-circular
      v-if="!$vuetify.breakpoint.mdAndDown"
      class="ml-3"
      size="85"
      width="13"
      :value="_value"
      color="primary"
      ><span class="noselect">{{
        steps.indexOf(step) + 1 || 0
      }}</span></v-progress-circular
    >
    <span
      class="
        step-text
        text-center
        dark-grey--text
        text--lighten-1 text-h2
        font-weight-bold
        noselect
      "
    >
      {{ step }}</span
    >
    <v-progress-linear
      class="linear-steps"
      :value="_value"
      color="primary"
      background-color="#e5e5e5"
      absolute
      height="8"
    >
    </v-progress-linear>
  </div>
</template>

<script>
export default {
  name: "ImageUploadProgress",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    steps: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    _value() {
      return this.value * (100 / this.steps.length);
    },
    step() {
      return this.steps[this.value - 1];
    },
  },
};
</script>
<style lang="scss" scoped>
.progress-wrapper {
  position: relative;
  min-height: 110px;
}
.step-text {
  display: inline-block;
  width: 70%;
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    width: 100%;
  }
}
::v-deep .v-progress-circular__info {
  font-size: 30px;
  font-weight: 800;
}
.linear-steps {
  bottom: 26px;
  width: 66%;
  margin-left: 18%;
  z-index: 0;
}
</style>
