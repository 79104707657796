<template>
  <div
    class="image-upload-wrapper fill-height d-flex flex-column"
    @drop.prevent
  >
    <ImageUploadProgress v-model="step" :steps="UPLOAD_STEPS" />
    <div class="upload-step-drawer">
      <ImageSelect
        v-model="file"
        :class="{ active: step == 1, obscured: step > 1 }"
      />
      <ImageCrop
        v-model="image"
        :crop="crop"
        @crop="on_crop"
        :class="{ active: step == 2, inactive: step < 2, obscured: step > 2 }"
        :imageWidth="image_width"
        :imageHeight="image_height"
        :square="square"
        :rectangle="rectangle"
        :wide-rectangle="wideRectangle"
      />
      <ImageUpload
        :preview-data="preview_data"
        :class="{ active: step == 3, inactive: step < 3 }"
        :upload="upload"
        @upload="on_upload"
        @is-valid="(v) => (upload_valid = v)"
      />
    </div>
    <div class="controls d-flex justify-space-between">
      <v-btn
        class="ml-10 mb-10"
        @click="back"
        color="primary"
        :disabled="is_sending_images || is_fetching_images"
        rounded
      >
        Back
      </v-btn>
      <v-btn
        v-if="step != 1"
        class="mr-10"
        @click="next"
        color="primary"
        rounded
        :disabled="step == 3 ? !upload_valid : false"
        :loading="is_sending_images || is_fetching_images"
      >
        {{ step == 2 ? "Crop" : "Upload" }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import ImageUploadProgress from "./ImageUploadProgress.vue";
import ImageSelect from "./ImageSelect.vue";
import ImageCrop from "./ImageCrop.vue";
import ImageUpload from "./ImageUpload.vue";

const UPLOAD_STEPS = ["Select", "Crop", "Upload"];

export default {
  name: "ImageUploader",
  components: {
    ImageUploadProgress,
    ImageSelect,
    ImageCrop,
    ImageUpload,
  },
  props: {
    shop_id: {
      type: String,
      default: "",
    },
    brand_id: {
      type: String,
      default: "",
    },
    wideRectangle: { type: Boolean, default: true },
    rectangle: { type: Boolean, default: true },
    square: { type: Boolean, deafult: true },
  },
  data() {
    return {
      file: null,
      image: null,
      image_height: 0,
      image_width: 0,
      step: 1,
      crop: false,
      upload: false,
      preview_data: null,
      upload_valid: false,
      UPLOAD_STEPS,
    };
  },
  computed: {
    ...mapState("FileExplorerStore", [
      "is_fetching_images",
      "is_sending_images",
      "current_path",
      "current_temporary_path",
    ]),
  },
  methods: {
    ...mapActions("FileExplorerStore", [
      "toggle_show_image_uploader",
      "set_image_to_upload",
      "upload_image",
      "set_path",
      "reset_temporary_structure",
      "reset_temporary_path",
    ]),
    back() {
      switch (this.step) {
        case 1:
          this.toggle_show_image_uploader();
          break;
        case 2:
          this.file = null;
          this.image = null;
          break;
      }
      this.step = Math.max(this.step - 1, 1);
    },
    next() {
      switch (this.step) {
        case 2:
          this.crop = !this.crop;
          break;
        case 3:
          this.upload = !this.upload;
          break;
      }
      this.step = Math.min(this.step + 1, this.UPLOAD_STEPS.length);
    },
    on_crop(crop) {
      if (!crop.canvas) return;

      this.preview_data = crop.canvas.toDataURL(crop.type);
      crop.canvas.toBlob((blob) => {
        const new_name = [
          this.file.name.split(".")[0],
          crop.type.split("/")[1] || this.file.name.split(".")[1],
        ].join(".");
        const file = new File([blob], new_name);
        this.set_image_to_upload(file);
      }, crop.type);
    },
    async on_upload({ file, path }) {
      const params = {
        shop_id: this.shop_id,
        brand_id: this.brand_id,
        file_path: path,
        file: file,
      };
      await this.upload_image(params);
      const new_path = [...this.current_path, ...this.current_temporary_path];
      this.set_path(new_path);
      this.reset_temporary_structure();
      this.reset_temporary_path();
      this.set_image_to_upload(null);
      this.step = 1;
      this.file = null;
      this.toggle_show_image_uploader();
    },
  },
  watch: {
    file(file) {
      if (!file) {
        this.image = null;
        return;
      } else {
        const reader = new FileReader();
        const component = this;
        reader.onload = (e) => {
          const image = new Image();
          image.onload = function () {
            component.image_height = this.height;
            component.image_width = this.width;
          };
          image.src = e.target.result;

          this.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.next();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.image-upload-wrapper {
  position: relative;
  background: white;
}
::v-deep .upload-step-drawer {
  position: relative;
  width: 100%;
  height: 100%;
  > * {
    position: absolute;
    width: 100%;
    height: inherit;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }
  .inactive {
    transform: translateX(100%);
  }
  .active {
    transform: translateX(0%);
  }
  .obscured {
    transform: translateX(-100%);
  }
}
</style>
