<template>
  <div class="d-flex latest-news br-8">
    <div
      class="--img"
      :style="`background-image: url(${latestNews.image.small_url})`"
    ></div>
    <div class="--text pa-4 text-left">
      <p class="font-weight-bold primary--text">Latest News</p>
      <p class="font-weight-bold">{{ latestNews.title }}</p>
      <p class="--excerpt">
        {{ latestNews.description }}
      </p>
      <p>
        <v-btn
          class="d-inline"
          elevation="0"
          small
          rounded
          color="primary"
          outlined
          ><span class="no-text-transform black--text font-weight-bold"
            >Read More</span
          ></v-btn
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LatestNewsPreview",
  props: {
    latestNews: { type: Object, default: () => {} },
  },
};
</script>

<style lang="scss" scoped>
.latest-news {
  border-radius: 8px;
  overflow: hidden;
  .--text {
    background-color: #fff;
    width: 50%;
  }
  .--img {
    background-position: center;
    background-size: cover;
    width: 50%;
  }
  .--excerpt {
    display: -webkit-box;
    max-height: 120px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
